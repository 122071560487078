//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from "axios";
import api from "../api.js";
export default {
  data: () => ({
    menuItems: [{ icon: "mdi-exit-to-app", title: "Menu", action: "gomenu" }],
    dialogMsg: false,
    dialogChoiceItem: false,
    bImportarArticle: false,
    itemOne: {
      itemId: 0,
      itemCode: "",
      itemReference: "",
      itemBarcode: "",
      itemName: "",
      qty: 0.0,
      text: "",
    },
    itemList: [],
    item: undefined,
  }),
  created() {
    //
  },
  mounted() {
    if (sessionStorage["sessDocLineId"] != 0) {
      this.omplirEdits();
    } else {
      this.newRecord();
    }
  },
  methods: {
    goBack() {
      this.$router.push("/listitems01list");
    },
    goMenu(action) {
      switch (action) {
        case "gomenu":
          this.$router.push("/mainmenu");
          break;
      }
    },
    qtyAdd(q) {
      this.itemOne.qty = parseFloat(this.itemOne.qty) + parseInt(q);
    },
    habilitarImportarArticle(habilitar, barcode) {
      if (habilitar) {
        this.bImportarArticle = true;
        if (barcode.length != 0) {
          this.itemOne.itemBarcode = barcode;
          this.itemOne.itemCode = "";
          this.setFocus(`TextField_ItemName`);
        }
      } else {
        this.bImportarArticle = false;
      }
    },
    setFocus(nomCamp) {
      setTimeout(() => {
        if (document.getElementById(nomCamp) != null) {
          document.getElementById(nomCamp).focus();
        }
      }, 200);
    },
    newRecord() {
      this.itemOne.docLineId = 0;
      this.itemOne.itemId = 0;
      this.itemOne.itemCode = "";
      this.itemOne.itemReference = "";
      this.itemOne.itemBarcode = "";
      this.itemOne.itemName = "";
      this.itemOne.qty = 1.0;
      this.itemOne.Text = "";
      sessionStorage["sessDocLineId"] = 0;
      this.setFocus("TextField_ItemCode");
      this.habilitarImportarArticle(false, "");
    },
    omplirEdits() {
      axios
        .post(api.URL() + "/api/v1/gllistagarticles01_getbyid", {
          token: sessionStorage.getItem("Token"),
          docYear: parseInt(sessionStorage["sessDocYear"]),
          docId: parseInt(sessionStorage["sessDocId"]),
          docLineId: parseInt(sessionStorage["sessDocLineId"]),
        })
        .then((response) => {
          this.itemOne = response.data[0];
          this.habilitarImportarArticle(this.itemOne.itemId == 0, "");
        })
        .catch(function (error) {
          this.msgAlert(error.response, true);
        });
    },
    appendUpdateRecord(bGoback) {
      this.dialog = false;
      if (parseInt(sessionStorage["sessDocLineId"]) == 0) {
        if (
          this.itemOne.itemCode.length != 0 ||
          (this.itemOne.itemName.length != 0 &&
            this.itemOne.itemBarcode.length != 0) // Necessitem la descripció i el codi de barres per "Fer alta"
        ) {
          axios
            .post(api.URL() + "/api/v1/gllistagarticles01_append", {
              token: sessionStorage.getItem("Token"),
              docYear: parseInt(sessionStorage["sessDocYear"]),
              docId: parseInt(sessionStorage["sessDocId"]),
              itemId: this.itemOne.itemId,
              itemCode: this.itemOne.itemCode,
              itemReference: this.itemOne.itemReference,
              itemBarcode: this.itemOne.itemBarcode,
              itemName: this.itemOne.itemName,
              qty: parseFloat(this.itemOne.qty),
              text: this.itemOne.text,
            })
            .then(() => {
              if (bGoback) {
                this.goBack();
              }
            })
            .catch(function (error) {
              this.msgAlert(error.response, true);
            });
        }
      } else {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles01_update", {
            token: sessionStorage.getItem("Token"),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docLineId: parseInt(sessionStorage["sessDocLineId"]),
            itemId: this.itemOne.itemId,
            itemCode: this.itemOne.itemCode,
            itemReference: this.itemOne.itemReference,
            itemBarcode: this.itemOne.itemBarcode,
            itemName: this.itemOne.itemName,
            qty: parseFloat(this.itemOne.qty),
            text: this.itemOne.text,
          })
          .then(() => {
            if (bGoback) {
              this.goBack();
            }
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    deleteRecord() {
      if (parseInt(sessionStorage["sessDocLineId"]) != 0) {
        axios
          .post(api.URL() + "/api/v1/gllistagarticles01_delete", {
            token: sessionStorage.getItem("Token"),
            docYear: parseInt(sessionStorage["sessDocYear"]),
            docId: parseInt(sessionStorage["sessDocId"]),
            docLineId: parseInt(sessionStorage["sessDocLineId"]),
          })
          .then(() => {
            //  this.newRecord();
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    findItem(strValue) {
      var self = this;
      if (strValue.trim != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_find", {
            token: sessionStorage.getItem("Token"),
            text: strValue,
          })
          .then((response) => {
            self.itemList = response.data;

            if (self.itemList == null) {
              self.dialogMsg = true;
            }
            if (self.itemList != null && self.itemList.length == 1) {
              self.articleGetById(self.itemList[0].itemId);
            }
            if (self.itemList != null && self.itemList.length > 1) {
              self.dialogChoiceItem = true;
            }
          })
          .catch(function (error) {
            self.msgAlert(error.response, true);
          });
      } else {
        self.$alert.show({
          message: "Empty value",
        });
      }
    },
    articleGetById(itemId) {
      if (itemId != null && itemId.trim != "") {
        axios
          .post(api.URL() + "/api/v1/garticles_getbyid", {
            token: sessionStorage.getItem("Token"),
            itemId: parseInt(itemId),
          })
          .then((response) => {
            this.item = response.data;
            this.itemOne.itemId = this.item.itemId;
            this.itemOne.itemCode = this.item.itemCode;
            this.itemOne.itemName = this.item.itemName;
          })
          .catch(function (error) {
            this.msgAlert(error.response, true);
          });
      }
    },
    msgAlert(missatge, close) {
      var onClose = () => {
        if (close && close == true) {
          this.$router.push("/");
        }
      };

      this.$alert.show({ message: missatge, onClose: onClose });
    },
  },
};
